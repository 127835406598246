import types from './types';

export interface LoginPayload {
  error?: { response?: Response };
  infos?: {
    id: string;
    expirationTime?: string;
    permissions?: string[];
    organizationId?: string;
  };
}
export interface LoginState {
  isLoading: boolean;
  isLoggedIn: boolean;
  expirationTime?: Date;
  permissions?: string[];
  userId?: string;
  organizationId?: string;
}

const defaultState: LoginState = {
  isLoading: false,
  isLoggedIn: false,
};

export interface LoginRequestPayload {
  emailAddress?: string | null;
  password?: string | null;
}

export type LoginResponsePayload = LoginPayload;

export type LoginAction =
  | { type: types.LOGIN_REQUEST; payload: LoginRequestPayload }
  | { type: types.LOGIN_RESPONSE; payload: LoginResponsePayload }
  | { type: types.LOGOUT_REQUEST; payload: undefined }
  | { type: types.LOGOUT_RESPONSE; payload: any }
  | { type: types.NEW_EXPIRATION_TIME; payload: string }
  | { type: types.VERIFY_2FA_REQUEST; payload: any }
  | { type: types.VERIFY_2FA_RESPONSE; payload: any }
  | { type: types.ENABLE_2FA_REQUEST; payload: string }
  | { type: types.ENABLE_2FA_RESPONSE; payload: any }
  | { type: types.REFRESH_2FA_REQUEST; payload: any }
  | { type: types.REFRESH_2FA_RESPONSE; payload: any };

function login(state: LoginState = defaultState, action: LoginAction): LoginState {
  switch (action.type) {
    case types.LOGIN_REQUEST:
      return { ...state, isLoading: true };
    case types.LOGIN_RESPONSE:
      return {
        ...state,
        isLoading: false,
        isLoggedIn: action.payload.infos !== undefined,
        expirationTime: action.payload.infos?.expirationTime
          ? new Date(action.payload.infos.expirationTime)
          : undefined,
        permissions: action.payload.infos?.permissions || [],
        userId: action.payload.infos?.id,
        organizationId: action.payload.infos?.organizationId,
      };
    case types.LOGOUT_RESPONSE:
      return { ...state, isLoggedIn: false, expirationTime: undefined };

    case types.NEW_EXPIRATION_TIME:
      return {
        ...state,
        expirationTime: new Date(action.payload),
      };

    case types.VERIFY_2FA_REQUEST:
    case types.VERIFY_2FA_RESPONSE:
      return { ...state, isLoading: false };
    case types.ENABLE_2FA_REQUEST:
    case types.ENABLE_2FA_RESPONSE:
      return { ...state };
    case types.REFRESH_2FA_REQUEST:
    case types.REFRESH_2FA_RESPONSE:
      return { ...state, isLoading: false };

    default:
      return state;
  }
}

export default login;
