import React, { ChangeEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import { Form, Table, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { RootState } from 'services/store';
import DrugRow from './DrugRow';
import './style.scss';
import { DrugWarnings } from './types';

export interface DrugDependencies {
  setSelectedDrugId: (drugId: string) => void;
  setShowDrugDetails: (show: boolean) => void;
  setShowUpdateDrug: (show: boolean) => void;
  setShowDeleteDrug: (show: boolean) => void;
}

function DrugsTable({
  setSelectedDrugId,
  setShowDrugDetails,
  setShowUpdateDrug,
  setShowDeleteDrug,
}: DrugDependencies): JSX.Element {
  const [query, setQuery] = useState<string>('');
  const [invalidSearch, setInvalidSearch] = useState<boolean>(false);
  const user = useSelector((state: RootState) => state.login);
  const drugs = useSelector((state: RootState) => state.drugs);
  const [filterNotValidatedDrug, setFilterNotValidatedDrug] = useState<boolean>(false);
  const { t } = useTranslation();
  const notValidatedDrugCount = drugs.filter((drug) =>
    drug.warnings?.includes(DrugWarnings.NOT_VALIDATED),
  ).length;

  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>): void => {
    setInvalidSearch(false);
    const regex = /^[a-zA-Z0-9 /()-]+$/;
    if (regex.test(e.target.value) || !e.target.value) {
      setQuery(e.target.value);
    } else {
      setInvalidSearch(true);
    }
  };

  const rows =
    drugs &&
    drugs
      .filter(
        (drug) =>
          (!query ||
            drug.name.toLowerCase().includes(query.toLowerCase()) ||
            drug.DIN.toLowerCase().includes(query.toLowerCase())) &&
          (!filterNotValidatedDrug ||
            (drug.warnings && drug.warnings.includes(DrugWarnings.NOT_VALIDATED))),
      )
      .sort((drugA, drugB) => drugA.name.localeCompare(drugB.name))
      .map((drug) => (
        <DrugRow
          key={drug.id}
          drug={drug}
          canUpdate={user?.permissions?.includes('drugs.update')}
          canDelete={user?.permissions?.includes('drugs.delete')}
          dependencies={{
            setSelectedDrugId,
            setShowDrugDetails,
            setShowUpdateDrug,
            setShowDeleteDrug,
          }}
        />
      ));
  return (
    <>
      <div style={{ display: 'flex', width: '85%' }}>
        <div style={{ position: 'relative', width: '75%' }}>
          <Form.Control
            type="text"
            onChange={handleChangeSearch}
            placeholder={`${t('search')}...`}
            isInvalid={invalidSearch}
          />
          <Form.Control.Feedback type="invalid">{t('invalidSearchQuery')}</Form.Control.Feedback>
          <FontAwesomeIcon
            icon="search"
            color="black"
            style={{
              background: 'transparent',
              border: 'none',
              cursor: 'pointer',
              display: 'inline-block',
              fontSize: 20,
              position: 'absolute',
              top: 10,
              right: 12,
              zIndex: 2,
            }}
          />
        </div>
        <ToggleButtonGroup className="ml-2" type="checkbox">
          <ToggleButton
            id="toggle-check"
            variant={notValidatedDrugCount > 0 ? 'outline-danger' : 'outline-secondary'}
            checked={filterNotValidatedDrug}
            value="1"
            onChange={(e) => setFilterNotValidatedDrug(e.currentTarget.checked)}
          >
            {t('notValidatedDrugCount', { notValidatedDrugCount })}
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      <br />
      <Table responsive bordered striped>
        <thead>
          <tr>
            <th style={{ width: 200 }}>{t('DIN')}</th>
            <th>{t('name')}</th>
            <th style={{ width: 200 }}>{t('strength')}</th>
            <th style={{ width: 200 }}>{t('format')}</th>
            {user?.permissions?.some(
              (perm) => perm === 'drugs.update' || perm === 'drugs.delete',
            ) && <th style={{ width: '10%' }}>{t('actions')}</th>}
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </>
  );
}

export default DrugsTable;
