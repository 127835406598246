enum Types {
  CREATE_BUCKET_REQUEST = 'CREATE_BUCKET_REQUEST',
  CREATE_BUCKET_RESPONSE = 'CREATE_BUCKET_RESPONSE',
  GET_BUCKETS_REQUEST = 'GET_BUCKETS_REQUEST',
  GET_BUCKETS_RESPONSE = 'GET_BUCKETS_RESPONSE',
  UPDATE_BUCKET_REQUEST = 'UPDATE_BUCKET_REQUEST',
  UPDATE_BUCKET_RESPONSE = 'UPDATE_BUCKET_RESPONSE',
  PATCH_BUCKET_REQUEST = 'PATCH_BUCKET_REQUEST',
  PATCH_BUCKET_RESPONSE = 'PATCH_BUCKET_RESPONSE',
  DELETE_BUCKET_REQUEST = 'DELETE_BUCKET_REQUEST',
  DELETE_BUCKET_RESPONSE = 'DELETE_BUCKET_RESPONSE',
  ACTIVATE_BUCKET_REQUEST = 'ACTIVATE_BUCKET_REQUEST',
  ACTIVATE_BUCKET_RESPONSE = 'ACTIVATE_BUCKET_RESPONSE',
  DEACTIVATE_BUCKET_REQUEST = 'DEACTIVATE_BUCKET_REQUEST',
  DEACTIVATE_BUCKET_RESPONSE = 'DEACTIVATE_BUCKET_RESPONSE',
  UNLOAD_BUCKET_REQUEST = 'UNLOAD_BUCKET_REQUEST',
  UNLOAD_BUCKET_RESPONSE = 'UNLOAD_BUCKET_RESPONSE',
  LOAD_BUCKET_REQUEST = 'LOAD_BUCKET_REQUEST',
  LOAD_BUCKET_RESPONSE = 'LOAD_BUCKET_RESPONSE',
  ADD_LOT_REQUEST = 'ADD_LOT_REQUEST',
  ADD_LOT_RESPONSE = 'ADD_LOT_RESPONSE',
  EMPTY_LOTS_REQUEST = 'EMPTY_LOTS_REQUEST',
  EMPTY_LOTS_RESPONSE = 'EMPTY_LOTS_RESPONSE',
  EDIT_QUANTITY_REQUEST = 'EDIT_QUANTITY_REQUEST',
  EDIT_QUANTITY_RESPONSE = 'EDIT_QUANTITY_RESPONSE',
  EDIT_BUCKET_REQUEST = 'EDIT_BUCKET_REQUEST',
  EDIT_BUCKET_RESPONSE = 'EDIT_BUCKET_RESPONSE',
  IN_REFILL_LIST_REQUEST = 'IN_REFILL_LIST_REQUEST',
  IN_REFILL_LIST_RESPONSE = 'IN_REFILL_LIST_RESPONSE',
  IMPORT_BUCKETLIST_REQUEST = 'IMPORT_BUCKETLIST_REQUEST',
  IMPORT_BUCKETLIST_RESPONSE = 'IMPORT_BUCKETLIST_RESPONSE',
}

export default Types;
