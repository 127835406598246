import React, { useEffect, useRef } from 'react';
import { LoadingButton } from 'components/shared/LoadingButton';
import { Formik, FormikHelpers } from 'formik';
import { Button, Form, Modal, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as yup from 'yup';
import { t } from 'i18next';
import { handleHttpResponseError } from 'components/shared/helpers';
import { Dispatch } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'services/store';
import { checkIsAdmin, checkIsChronoTech } from 'utils/permissions';
import { editBucket } from 'services/buckets/operations';
import { Bucket, EditBucketInfos } from '../types';
import UPCs from './UPCs';

interface EditBucketModalProps {
  show: boolean;
  bucket: Bucket;
  close: () => void;
}

const validationSchema = yup.object().shape({
  DIN: yup.string().matches(/^\d+$/, 'incorrectDINFormat').length(8, 'incorrectDINLength'),
  UPCs: yup.array().of(
    yup
      .string()
      .matches(/^\d+$/, 'incorrectUPCFormat')
      .test(
        'lengthCheck',
        'incorrectUPCLength',
        (value) => !value || value.length === 12 || value.length === 13,
      )
      .required('UPCRequired'),
  ),
  monthlyQty: yup.number().integer(t('wholeQtyOnly')).min(0),
});

export interface FormValues {
  DIN: string;
  UPCs: string[] | undefined;
  monthlyQty: number | undefined;
}

const EditBucketModal = ({ show, bucket, close }: EditBucketModalProps) => {
  const dispatch = useDispatch<Dispatch<any>>();
  const isAdmin = checkIsAdmin() || checkIsChronoTech();
  const drugs = useSelector((state: RootState) => state.drugs);
  const drug = drugs.find((d) => d.DIN === bucket.DIN);

  const initialValues = {
    DIN: bucket.DIN,
    UPCs: bucket.UPCs,
    monthlyQty: bucket.monthlyQty,
  };

  const inputRef = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    if (show && inputRef.current) {
      inputRef.current.focus();
    }
  }, [show]);

  const handleSubmit = async (
    values: FormValues,
    { setSubmitting, setFieldError }: FormikHelpers<FormValues>,
  ): Promise<any> => {
    setSubmitting(true);
    try {
      let bucketInfos: EditBucketInfos;
      if (isAdmin) {
        if (values.DIN && !drugs.find((d) => d.DIN === values.DIN)) {
          setFieldError('DIN', t('unknownDIN'));
          setSubmitting(false);
          return;
        }
        bucketInfos = {
          DIN: values.DIN ? values.DIN : bucket.DIN,
          UPCs: values.UPCs ? values.UPCs : drug?.UPC,
          monthlyQty:
            values.monthlyQty !== null && values.monthlyQty?.toString() !== ''
              ? values.monthlyQty
              : bucket.monthlyQty,
        };
        if (values.DIN && values.monthlyQty === bucket.monthlyQty) {
          bucketInfos.monthlyQty = 0;
        }
      } else {
        bucketInfos = {
          monthlyQty:
            values.monthlyQty !== null && values.monthlyQty?.toString() !== ''
              ? values.monthlyQty
              : bucket.monthlyQty,
        };
      }

      await editBucket(bucket.id, bucketInfos)(dispatch);

      setSubmitting(false);
      close();
    } catch (err) {
      handleHttpResponseError(err, 'FAILED EDIT BUCKET', setFieldError);
    }
    setSubmitting(false);
  };

  return (
    <Modal show={show} onHide={close}>
      <Modal.Header>
        <Modal.Title>
          {t('editBucket', {
            position: bucket.position,
            drugName: drug?.name,
            force: drug?.force,
          })}
        </Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          handleBlur,
          handleSubmit,
          handleChange,
          isSubmitting,
          setFieldValue,
        }) => {
          useEffect(() => {
            if (values.DIN !== initialValues.DIN) {
              setFieldValue('UPCs', ['']);
            }
          }, [values.DIN, setFieldValue]);

          return (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                {isAdmin && (
                  <Form.Group className="mb-3" controlId="DIN">
                    {initialValues.DIN !== values.DIN && (
                      <Alert variant="warning">
                        <FontAwesomeIcon
                          style={{ marginRight: '1.5%' }}
                          icon="exclamation-triangle"
                          size="1x"
                          color="orange"
                        />
                        {t('warningDiscsCompatibility')}
                      </Alert>
                    )}
                    <Form.Label>DIN</Form.Label>
                    <Form.Control
                      type="string"
                      name="DIN"
                      value={values.DIN}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder={bucket.DIN}
                      isInvalid={!!errors.DIN}
                    />
                    <Form.Control.Feedback type="invalid">
                      {t(`${errors.DIN}`)}
                    </Form.Control.Feedback>
                  </Form.Group>
                )}

                {isAdmin && (
                  <UPCs
                    errors={errors}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    values={values}
                    drug={drug}
                  />
                )}

                <Form.Group className="mb-3" controlId="monthlyQty">
                  <Form.Label>{t('monthlyQty')}</Form.Label>
                  <Form.Control
                    type="number"
                    min="0"
                    name="monthlyQty"
                    value={values.monthlyQty}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder={bucket.monthlyQty?.toString()}
                    isInvalid={!!errors.monthlyQty}
                    ref={inputRef}
                  />
                  <Form.Control.Feedback type="invalid">
                    {t(`${errors.monthlyQty}`)}
                  </Form.Control.Feedback>
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <LoadingButton
                  variant="primary"
                  type="submit"
                  loading={isSubmitting}
                  disabled={isSubmitting}
                >
                  {t('submit')}
                </LoadingButton>
                <Button variant="secondary" onClick={close}>
                  {t('close')}
                </Button>
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default EditBucketModal;
