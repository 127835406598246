import React, { useState } from 'react';
import { handleHttpResponseError } from 'components/shared/helpers';
import { Formik, FormikHelpers, ErrorMessage } from 'formik';
import { Button, Collapse, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { LoadingButton } from 'components/shared/LoadingButton';
import { createUser } from 'services/users/operations';
import { NewUser, UserRole, AssignableUserRole } from 'components/users/types';
import { createUserValidation } from 'components/users/validation';

interface FormValues {
  userName: string;
  password: string;
  confirmPassword: string;
  emailAddress: string;
  lastName: string;
  firstName: string;
  role: UserRole;
}

interface AddUserFormProps {
  organizationId: string;
}

const AddUserForm = ({ organizationId }: AddUserFormProps) => {
  const dispatch = useDispatch<Dispatch<any>>();

  const initialValues: FormValues = {
    userName: '',
    password: '',
    confirmPassword: '',
    emailAddress: '',
    lastName: '',
    firstName: '',
    role: UserRole.TECH,
  };

  const [open, setOpen] = useState(false);

  const handleSubmit = async (
    values: FormValues,
    { setSubmitting, setFieldError, resetForm }: FormikHelpers<FormValues>,
  ): Promise<any> => {
    setSubmitting(true);
    try {
      const newUser: NewUser = {
        organizationId,
        userName: values.userName,
        password: values.confirmPassword,
        firstName: values.firstName,
        lastName: values.lastName,
        emailAddress: values.emailAddress,
        role: values.role,
      };
      await createUser(newUser)(dispatch);
      resetForm();
      setOpen(false);
    } catch (err) {
      handleHttpResponseError(err, 'FAILED UPLOAD USER', setFieldError);
    }
    setSubmitting(false);
  };

  return (
    <>
      <div className="collapse-button-container">
        <Button
          onClick={() => setOpen(!open)}
          aria-controls="user-collapse-form"
          aria-expanded={open}
          variant="link"
        >
          {t('addUser')}{' '}
          {open ? <FontAwesomeIcon icon="angle-up" /> : <FontAwesomeIcon icon="angle-down" />}
        </Button>
      </div>
      <Collapse className="collapse-form" in={open}>
        <div id="user-collapse-form">
          <Formik
            initialValues={initialValues}
            validationSchema={createUserValidation}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <Form className="organization-user-form" onSubmit={handleSubmit}>
                <Form.Group controlId="role">
                  <Form.Label>{t('role')}</Form.Label>
                  <Form.Control
                    as="select"
                    custom
                    onChange={(event) => {
                      setFieldValue('role', event.target.value);
                    }}
                  >
                    {AssignableUserRole.map((role) => (
                      <option key={role} value={role}>
                        {role}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>

                <Form.Group controlId="userName">
                  <Form.Label>{t('userName')}</Form.Label>
                  <Form.Control
                    autoComplete="off"
                    className={errors.userName && touched.userName ? 'error-text' : ''}
                    type="text"
                    name="userName"
                    value={values.userName}
                    onBlur={handleBlur}
                    onChange={(event) => {
                      setFieldValue('userName', event.target.value);
                    }}
                  />
                  <ErrorMessage
                    name="userName"
                    render={(msg) => <span className="error-message">{t(msg)}</span>}
                  />
                </Form.Group>

                <Form.Group controlId="password">
                  <Form.Label>{t('password')}</Form.Label>
                  <Form.Control
                    autoComplete="off"
                    className={errors.password && touched.password ? 'error-text' : ''}
                    type="password"
                    name="password"
                    value={values.password}
                    onBlur={handleBlur}
                    onChange={(event) => {
                      setFieldValue('password', event.target.value);
                    }}
                  />
                  <ErrorMessage
                    name="password"
                    render={(msg) => <span className="error-message">{t(msg)}</span>}
                  />
                </Form.Group>

                <Form.Group controlId="confirmPassword">
                  <Form.Label>{t('confirmPassword')}</Form.Label>
                  <Form.Control
                    autoComplete="off"
                    className={
                      errors.confirmPassword && touched.confirmPassword ? 'error-text' : ''
                    }
                    type="password"
                    name="confirmPassword"
                    value={values.confirmPassword}
                    onBlur={handleBlur}
                    onChange={(event) => {
                      setFieldValue('confirmPassword', event.target.value);
                    }}
                  />
                  <ErrorMessage
                    name="confirmPassword"
                    render={(msg) => <span className="error-message">{t(msg)}</span>}
                  />
                </Form.Group>

                <Form.Group controlId="emailAddress">
                  <Form.Label>{t('emailAddress')}</Form.Label>
                  <Form.Control
                    autoComplete="off"
                    className={errors.emailAddress && touched.emailAddress ? 'error-text' : ''}
                    type="text"
                    name="emailAddress"
                    value={values.emailAddress}
                    onBlur={handleBlur}
                    onChange={(event) => {
                      setFieldValue('emailAddress', event.target.value);
                    }}
                  />
                  <ErrorMessage
                    name="emailAddress"
                    render={(msg) => <span className="error-message">{t(msg)}</span>}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>{t('lastName')}</Form.Label>
                  <Form.Control
                    autoComplete="off"
                    className={errors.lastName && touched.lastName ? 'error-text' : ''}
                    type="text"
                    name="lastName"
                    value={values.lastName}
                    onBlur={handleBlur}
                    onChange={(event) => {
                      setFieldValue('lastName', event.target.value);
                    }}
                  />
                  <ErrorMessage
                    name="lastName"
                    render={(msg) => <span className="error-message">{t(msg)}</span>}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>{t('firstName')}</Form.Label>
                  <Form.Control
                    autoComplete="off"
                    className={errors.firstName && touched.firstName ? 'error-text' : ''}
                    type="text"
                    name="firstName"
                    value={values.firstName}
                    onBlur={handleBlur}
                    onChange={(event) => {
                      setFieldValue('firstName', event.target.value);
                    }}
                  />
                  <ErrorMessage
                    name="firstName"
                    render={(msg) => <span className="error-message">{t(msg)}</span>}
                  />
                </Form.Group>
                <div className="collapse-submit-button-container">
                  <LoadingButton
                    variant="primary"
                    type="submit"
                    loading={isSubmitting}
                    disabled={isSubmitting}
                  >
                    {t('submit')}
                  </LoadingButton>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Collapse>
    </>
  );
};
export default AddUserForm;
