import React, { useState } from 'react';
import { Dispatch } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Form, Toast } from 'react-bootstrap';
import { Formik, FormikHelpers, ErrorMessage } from 'formik';
import { handleHttpResponseError } from 'components/shared/helpers';
import { LoadingButton } from 'components/shared/LoadingButton';
import { useTranslation } from 'react-i18next';
import { deleteUser, patchUser, toggleVAssyst, toggleTwoFA } from 'services/users/operations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RootState } from 'services/store';
import ConfirmationModal from 'components/shared/ConfirmationModal/ConfirmationModal';
import PasswordResetModal from 'components/users/PasswordResetModal';
import { User, UserRole, AssignableUserRole, UpdateUser, UserPermissions, twoFA } from './types';
import { getUserRole } from './helpers';
import { patchUserValidation } from './validation';
import './styles.scss';

interface FormValues {
  userName: string;
  lastName: string;
  firstName: string;
  emailAddress: string;
  role: UserRole;
  twoFA: twoFA;
}

interface UserRowProps {
  user: User;
  isAdmin: boolean;
  showOrganization: boolean;
}

const UserRow = ({ user, isAdmin, showOrganization }: UserRowProps): JSX.Element => {
  const dispatch = useDispatch<Dispatch<any>>();
  const { t } = useTranslation();
  const organizations = useSelector((state: RootState) => state.organizations);
  const userOrganization = organizations.find((o) => o.id === user.organizationId);
  const [deletingUser, setDeletingUser] = useState<boolean>(false);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>('');
  const [updatingPassword, setUpdatingPassword] = useState<boolean>(false);
  const [editingUser, setEditingUser] = useState<boolean>(false);

  const initialValues: UpdateUser = {
    userName: user.userName,
    lastName: user.lastName,
    firstName: user.firstName,
    emailAddress: user.emailAddress,
    role:
      getUserRole(user.permissions) === UserRole.UNKNOWN
        ? UserRole.TECH
        : getUserRole(user.permissions),
    twoFA: user.twoFA,
  };

  const userDeleteConfirmationMessage = (
    <>
      <p>{t('deleteUserMessage')}</p>
      <div>
        <p style={{ color: 'red' }}>
          <strong>
            {user?.userName} {user?.firstName} {user?.lastName}
          </strong>
        </p>
      </div>
      <p>{t('deleteUserConfirmation')}</p>
    </>
  );

  const handleDeleteUser = async () => {
    try {
      if (deleteUser) {
        await deleteUser(user.id)(dispatch);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setDeletingUser(false);
    }
  };

  const handleToggleVAssyst = async (id: string) => {
    try {
      await toggleVAssyst(id)(dispatch);
    } catch (err) {
      console.error(err);
    }
  };

  const handleToggleTwoFA = async (id: string) => {
    try {
      await toggleTwoFA(id)(dispatch);
      if (user.twoFA && user.twoFA.isEnabled) {
        setShowToast(true);
        setToastMessage(t('twoFADisabled'));
      } else {
        setShowToast(true);
        setToastMessage(t('twoFAEnabled'));
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleSubmit = async (
    values: FormValues,
    { setSubmitting, setFieldError }: FormikHelpers<FormValues>,
  ) => {
    try {
      setSubmitting(true);
      const updatedUserFields = {
        ...(values.userName !== initialValues.userName ? { userName: values.userName } : {}),
        ...(values.lastName !== initialValues.lastName ? { lastName: values.lastName } : {}),
        ...(values.firstName !== initialValues.firstName ? { firstName: values.firstName } : {}),
        ...(values.emailAddress !== initialValues.emailAddress
          ? { emailAddress: values.emailAddress }
          : {}),
        ...(values.role !== getUserRole(user.permissions) ? { role: values.role } : {}),
      };
      if (Object.keys(updatedUserFields).length > 0) {
        await patchUser(user.id, updatedUserFields)(dispatch);
      }
      setEditingUser(false);
    } catch (err) {
      handleHttpResponseError(err, 'FAILED UPDATE USER', setFieldError);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={patchUserValidation}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
          <tr key={user.id}>
            <td>
              {editingUser ? (
                <Form.Group className="in-table-form-input" controlId="userName">
                  <Form.Control
                    className={errors.userName && touched.userName ? 'error-text' : ''}
                    type="text"
                    name="userName"
                    defaultValue={values.userName}
                    onChange={(event) => setFieldValue('userName', event.target.value)}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    name="userName"
                    render={(msg) => <span className="error-message">{t(msg)}</span>}
                  />
                </Form.Group>
              ) : (
                user.userName
              )}
            </td>

            <td>
              {editingUser ? (
                <Form.Group className="in-table-form-input" controlId="lastName">
                  <Form.Control
                    className={errors.lastName && touched.lastName ? 'error-text' : ''}
                    type="text"
                    name="lastName"
                    defaultValue={values.lastName}
                    onChange={(event) => setFieldValue('lastName', event.target.value)}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    name="lastName"
                    render={(msg) => <span className="error-message">{t(msg)}</span>}
                  />
                </Form.Group>
              ) : (
                user.lastName
              )}
            </td>

            <td>
              {editingUser ? (
                <Form.Group className="in-table-form-input" controlId="firstName">
                  <Form.Control
                    className={errors.firstName && touched.firstName ? 'error-text' : ''}
                    type="text"
                    name="firstName"
                    defaultValue={values.firstName}
                    onChange={(event) => setFieldValue('firstName', event.target.value)}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    name="firstName"
                    render={(msg) => <span className="error-message">{t(msg)}</span>}
                  />
                </Form.Group>
              ) : (
                user.firstName
              )}
            </td>

            <td>
              {editingUser ? (
                <Form.Group className="in-table-form-input" controlId="emailAddress">
                  <Form.Control
                    className={errors.emailAddress && touched.emailAddress ? 'error-text' : ''}
                    type="text"
                    name="emailAddress"
                    defaultValue={values.emailAddress}
                    onChange={(event) => setFieldValue('emailAddress', event.target.value)}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    name="emailAddress"
                    render={(msg) => <span className="error-message">{t(msg)}</span>}
                  />
                </Form.Group>
              ) : (
                user.emailAddress
              )}
            </td>

            {showOrganization && isAdmin && (
              <td>{userOrganization ? userOrganization.name : user.organizationId}</td>
            )}

            <td>
              {editingUser && isAdmin ? (
                <Form.Group className="in-table-form-input" controlId="role">
                  <Form.Control
                    as="select"
                    custom
                    onChange={(event) => {
                      setFieldValue('role', event.target.value);
                    }}
                    defaultValue={values.role}
                  >
                    {AssignableUserRole.map((role) => (
                      <option key={role} value={role}>
                        {role}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              ) : (
                getUserRole(user.permissions)
              )}
            </td>

            <td style={{ textAlign: 'center' }}>
              <input
                disabled={!isAdmin}
                type="checkbox"
                onChange={() => {
                  handleToggleVAssyst(user.id);
                }}
                checked={!!user.permissions.includes(UserPermissions.MACHINES_V_ASSYST)}
              />
            </td>

            <td style={{ textAlign: 'center' }}>
              <input
                disabled={!isAdmin}
                type="checkbox"
                onChange={() => {
                  handleToggleTwoFA(user.id);
                }}
                checked={user.twoFA ? !!user.twoFA.isEnabled : false}
              />
            </td>

            <td>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'stretch',
                }}
              >
                <Button
                  size="sm"
                  onClick={() => setUpdatingPassword(true)}
                  variant="outline-secondary"
                >
                  <FontAwesomeIcon size="sm" icon="key" />
                </Button>
                {editingUser ? (
                  <LoadingButton
                    onClick={() => handleSubmit()}
                    size="sm"
                    variant="outline-success"
                    type="submit"
                    loading={isSubmitting}
                    disabled={isSubmitting}
                  >
                    <FontAwesomeIcon size="sm" icon="check" />
                  </LoadingButton>
                ) : (
                  <Button
                    size="sm"
                    onClick={() => setEditingUser(true)}
                    variant="outline-secondary"
                  >
                    <FontAwesomeIcon size="sm" icon="pen" />
                  </Button>
                )}
                <Button size="sm" onClick={() => setDeletingUser(true)} variant="outline-danger">
                  <FontAwesomeIcon size="sm" icon="trash" />
                </Button>
              </div>
            </td>
          </tr>
        )}
      </Formik>
      <ConfirmationModal
        show={deletingUser}
        onHide={() => setDeletingUser(false)}
        onConfirm={handleDeleteUser}
        message={userDeleteConfirmationMessage}
      />
      <PasswordResetModal
        show={updatingPassword}
        onHide={() => setUpdatingPassword(false)}
        user={user}
      />
      <Toast
        show={showToast}
        onClose={() => setShowToast(false)}
        onClick={() => setShowToast(false)}
        className="bg-success toast-alert userrow-toastalert"
        autohide
        delay={5000}
      >
        <Toast.Body>
          <p>{toastMessage}</p>
        </Toast.Body>
      </Toast>
    </>
  );
};

export default UserRow;
