import React from 'react';
import { Drug } from 'components/drugs/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { invalidateDrugUPC, validateDrugUPC } from 'services/drugs/operations';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import './style.scss';

interface UPCValidationListProps {
  drugs: Drug[];
}

const UPCValidationList = ({ drugs }: UPCValidationListProps) => {
  const dispatch = useDispatch<Dispatch<any>>();
  return (
    <div className="upc-list">
      {drugs.map((drug) => (
        <div>
          <span>
            <b>{drug.name.toUpperCase()}</b>
          </span>{' '}
          <span>{drug.force ? drug.force.toUpperCase() : ''}</span> <span>(DIN {drug.DIN})</span>
          {drug.newUPC.map((UPC) => (
            <div className="upc-to-validate">
              <span>{UPC}</span>
              <Button
                variant="outline-success"
                onClick={() => validateDrugUPC(drug.id, UPC)(dispatch)}
              >
                <FontAwesomeIcon icon="check" size="1x" />
              </Button>
              <Button
                variant="outline-danger"
                onClick={() => invalidateDrugUPC(drug.id, UPC)(dispatch)}
              >
                <FontAwesomeIcon icon="times" size="1x" />
              </Button>
            </div>
          ))}
          <hr />
        </div>
      ))}
    </div>
  );
};

export default UPCValidationList;
