import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'services/store';
import { getSocket } from 'services/sockets';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// the eslint disable is part of the BYPASS
import ExpiredDrugsModal from 'components/buckets/ExpiredDrugsModal/ExpiredDrugsModal';
import './style.scss';
import SingleRefillModal from 'components/buckets/RefillModals/SingleRefillModal';
import { Bucket } from 'components/buckets/types';
import ManualExclusionModal from '../Modals/ManualExclusionModal';
import { ActiveRow } from '../ExpandedOrder';
import DistributeManuallyIcon from './DistributeManuallyIcon';
import { DrugOrder, DrugOrderStatus, Order } from '../../types';
import ExpiredDrugIcon from './ExpiredDrugIcon';

interface IncludedRowProps {
  order: Order;
  drugOrder: DrugOrder;
  listNum: number;
  splitted: boolean;
  setActiveRow: (activeRow: ActiveRow) => void;
  activeRow: ActiveRow;
}

const IncludedRow = ({
  order,
  drugOrder,
  listNum,
  splitted,
  setActiveRow,
  activeRow,
}: IncludedRowProps): JSX.Element => {
  const isActiveRow = activeRow && activeRow.DIN === drugOrder.DIN;
  const drugs = useSelector((state: RootState) => state.drugs);
  const buckets = useSelector((state: RootState) => state.buckets);
  const drug = drugs.find((d) => d.DIN === drugOrder.DIN);
  const [manuallyExclude, setManuallyExclude] = useState<boolean>(false);
  const [expiredDrug, setExpiredDrug] = useState<string>('');
  const [refillBucket, setRefillBucket] = useState<Bucket | null>(null);

  const [ongoingDrug, setOngoingDrug] = useState<DrugOrder | undefined>(
    drugOrder.distributions.some((d) => d.status === DrugOrderStatus.ONGOING)
      ? drugOrder
      : undefined,
  );

  useEffect(() => {
    const socket = getSocket();
    socket?.on('orders.progress', (progressData) => {
      if (progressData.machineId === order.machineId) {
        if (progressData.ongoingDrug && progressData.ongoingDrug.DIN === drugOrder.DIN) {
          setOngoingDrug(progressData.ongoingDrug);
        } else {
          setOngoingDrug(undefined);
        }
      }
    });
  });

  const handleRefill = () => {
    const bucket = buckets.find((b) => b.DIN === drugOrder.DIN);
    if (
      bucket &&
      drugOrder.distributions.some((dist) => dist.status === DrugOrderStatus.EXPIRED_IN_BUCKET)
    ) {
      setExpiredDrug(bucket.id);
    } else if (bucket) {
      setRefillBucket(bucket);
    }
  };

  const toggleBlisterCard = () => {
    if (isActiveRow) {
      setActiveRow({ DIN: '', excluded: true });
    } else {
      setActiveRow({ DIN: drugOrder.DIN, excluded: false });
    }
  };

  const isDrugDistributed = (): boolean => {
    if (
      drugOrder.distributions
        .filter((d) => !d.isExcluded)
        .every((d) => d.status === DrugOrderStatus.DISTRIBUTED)
    ) {
      return true;
    }
    const firstTrayCompleted: boolean = order.progression?.nbCardsProduced === 2;
    const currentBarcodes = firstTrayCompleted
      ? order.barcodes.slice(2)
      : order.barcodes.slice(0, 2);

    return drugOrder.distributions.some(
      (d) =>
        currentBarcodes.includes(d.barcode) &&
        !d.isExcluded &&
        d.status === DrugOrderStatus.DISTRIBUTED,
    );
  };

  const isDrugOngoing = (): boolean => {
    if (ongoingDrug) {
      return (
        ongoingDrug &&
        ongoingDrug.distributions.some(
          (distribution) => distribution.status === DrugOrderStatus.ONGOING,
        )
      );
    }
    return false;
  };

  return (
    <div className="included-row">
      <span className="completed-cell">
        {isDrugDistributed() && <FontAwesomeIcon icon="check" size="1x" color="green" />}
        {isDrugOngoing() && (
          <FontAwesomeIcon
            icon="sync"
            size="1x"
            color="#007bff"
            className="pendingIcon"
            style={{ marginRight: 15 }}
          />
        )}
        {drugOrder.distributions.some((d) => d.status === DrugOrderStatus.EXPIRED_IN_BUCKET) && (
          <ExpiredDrugIcon />
        )}
        {drugOrder.distributions.some((d) => d.status === DrugOrderStatus.NO_STOCK) && (
          <FontAwesomeIcon icon="exclamation-triangle" size="1x" color="red" />
        )}
      </span>
      <span className="split-cell">
        {listNum}
        {splitted ? '*' : ' '}
      </span>
      <span className="drugName-cell">
        <Button onClick={handleRefill} variant="link">
          {drug ? drug.name.toUpperCase() : '- UNKNOWN DRUG -'}{' '}
        </Button>
        <span> DIN {drugOrder.DIN}</span>
      </span>
      <span className="drugForce-cell">{drug && drug.force ? drug.force.toUpperCase() : ''}</span>
      <span className="included-buttons-cell">
        <Button
          /* BYPASS disabled={order.status === OrderStatus.INTRAY} */
          onClick={() => setManuallyExclude(true)}
          variant="outline-secondary"
        >
          <DistributeManuallyIcon />
        </Button>
        <Button
          variant={isActiveRow ? 'secondary' : 'outline-secondary'}
          onClick={toggleBlisterCard}
        >
          <FontAwesomeIcon icon="braille" />
        </Button>
      </span>

      <ManualExclusionModal
        drugOrder={drugOrder}
        drug={drug}
        orderId={order.id}
        show={manuallyExclude}
        close={() => setManuallyExclude(false)}
      />
      <ExpiredDrugsModal
        isOpen={!!expiredDrug}
        bucketsId={[expiredDrug]}
        onClose={() => setExpiredDrug('')}
      />
      {refillBucket && (
        <SingleRefillModal
          isOpen={!!refillBucket}
          bucket={refillBucket}
          onClose={() => setRefillBucket(null)}
        />
      )}
    </div>
  );
};

export default IncludedRow;
