export interface Lot {
  id: string;
  UPC: string;
  quantity: number;
  lotNumber: string;
  expirationDate: string;
}

export interface EditBucketInfos {
  DIN?: string;
  UPCs?: string[];
  monthlyQty?: number;
}

export enum DeactivatedReason {
  BACK_ORDER = 'BACK_ORDER', // When the drug going in is not in stock
  ORDERED = 'ORDERED', // When the drug going in is not currently in stock but should be ddelivered soon
  MAINTENANCE = 'MAINTENANCE', // If there is a problematic with the bucket
  EXCHANGE = 'EXCHANGE', // If the bucket is waiting to be replace (generic)
  EXPIRED_DRUGS = 'EXPIRED_DRUGS', // If the bucket contain expired drugs.
  OTHER = 'OTHER',
}

export interface Bucket {
  id: string;
  organizationId: string;
  DIN: string;
  UPCs?: string[];
  position: string;
  lots: Lot[];
  quantity: number;
  isDeactivated: boolean;
  deactivatedReason?: DeactivatedReason;
  permissions: {
    canActivate: boolean;
    canDeactivate: boolean;
  };
  nearestExpirationDate?: string | undefined;
  toRefill: boolean;
  monthlyQty?: number;
}
