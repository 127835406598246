/* eslint-disable */
import React from 'react';
import { DrugDistribution, DrugOrderExcludedReason } from 'components/orders/types';
import BlisterCardRow from './BlisterCardRow';
import { t } from 'i18next';

interface SingleBlisterCardProps {
  barcode: string;
  blisterCard: DrugDistribution;
  DIN: string;
  traySide: string;
}

const SingleBlisterCard = ({ barcode, blisterCard, DIN, traySide }: SingleBlisterCardProps) => {
  const conflictDistributionWarning = () => (
    <div style={{ textAlign: 'center', margin: '3% auto', maxWidth: '75%' }}>
      <strong>{t('conflictualDistribution')}</strong>
    </div>
  );
  return (
    <div className={traySide}>
      <div className="blister-card-id">
        <strong>{barcode}</strong>
      </div>
      {blisterCard.excludedReason === DrugOrderExcludedReason.DUPLICATE_DIN
        ? conflictDistributionWarning()
        : Array.from({ length: 7 }, (_, i) => (
            <BlisterCardRow
              key={`week-${i}-${DIN}-${blisterCard.barcode}`}
              DIN={DIN}
              distribution={blisterCard}
              day={i}
            />
          ))}
    </div>
  );
};

export default SingleBlisterCard;
