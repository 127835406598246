import {
  loginRequest,
  loginResponse,
  logoutRequest,
  logoutResponse,
  verify2FARequest,
  verify2FAResponse,
  enable2FARequest,
  enable2FAResponse,
  refresh2FARequest,
  refresh2FAResponse,
} from './actions';
import {
  login as APIlogin,
  logout as APIlogout,
  refreshToken as APIRefreshToken,
  verify2FA as APIverify2FA,
  enable2FA as APIenable2FA,
  refresh2FA as APIRefresh2FA,
} from './endpoints';

export function login(emailAddress: string | null = null, password: string | null = null) {
  return (dispatch): Promise<ReturnType<typeof loginResponse>> => {
    dispatch(loginRequest({ emailAddress, password }));
    return APIlogin({ emailAddress, password })
      .then((res) => dispatch(loginResponse(res)))
      .catch((err) => dispatch(loginResponse({ error: err })));
  };
}

export function refreshToken() {
  return APIRefreshToken();
}

export function logout() {
  return (dispatch): Promise<ReturnType<typeof logoutResponse>> => {
    dispatch(logoutRequest());
    return APIlogout().then((res) => dispatch(logoutResponse(res)));
  };
}

export function verify2FA(userId, token, rememberDevice) {
  return (dispatch): Promise<ReturnType<typeof verify2FAResponse>> => {
    dispatch(verify2FARequest({ userId, token, rememberDevice }));
    return APIverify2FA({ userId, token, rememberDevice })
      .then((res) => dispatch(verify2FAResponse(res)))
      .catch((err) => dispatch(verify2FAResponse({ error: err })));
  };
}

export function enable2FA(userId: string) {
  return (dispatch): Promise<ReturnType<typeof enable2FAResponse>> => {
    dispatch(enable2FARequest(userId));
    return APIenable2FA({ userId })
      .then((res) => dispatch(enable2FAResponse(res)))
      .catch((err) => dispatch(enable2FAResponse({ error: err })));
  };
}

export function refresh2FA(userId, token, rememberDevice) {
  return (dispatch): Promise<ReturnType<typeof refresh2FAResponse>> => {
    dispatch(refresh2FARequest({ userId, token, rememberDevice }));
    return APIRefresh2FA({ userId, token, rememberDevice })
      .then((res) => dispatch(refresh2FAResponse(res)))
      .catch((err) => dispatch(refresh2FAResponse({ error: err })));
  };
}

const operations = {
  login,
  logout,
  refreshToken,
  verify2FA,
  enable2FA,
  refresh2FA,
};

export default operations;
