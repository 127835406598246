import React, { useState } from 'react';
import { Dispatch } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Modal from 'react-bootstrap/Modal';
import ToggleButton from 'react-bootstrap/ToggleButton';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { HttpResponseError } from 'services/api';
import { unloadBucket, activateBucket } from 'services/buckets/operations';
import { RootState } from 'services/store';
import { checkIsAdmin, checkIsChronoTech } from 'utils/permissions';
import { Bucket, DeactivatedReason } from './types';
import EditQuantityModal from './EditQuantityModal';
import EditBucketModal from './EditBucketModal';
import DeactivateBucketModal from './DeactivateBucketModal';
import './style.scss';
import { getCurrentMonth, getNextMonthFromNow } from './helpers';

interface BucketRowProps {
  bucket: Bucket;
  onEnterBucket: (bucket: Bucket) => void;
  onUnloadingError: (error: string) => void;
}

const BucketRow = ({ bucket, onEnterBucket, onUnloadingError }: BucketRowProps): JSX.Element => {
  const [bucketUnloading, setBucketUnloading] = useState<Record<string, boolean>>({});
  const [modalToEmptyLot, setModalToEmptyLot] = useState<boolean>(false);
  const [modalEditQuantity, setModalEditQuantity] = useState<boolean>(false);
  const [deactivateBucket, setDeactivateBucket] = useState<boolean>(false);
  const [chosenAction, setChosenAction] = useState('toEmpty');
  const [editBucket, setEditBucket] = useState<boolean>(false);
  const drugs = useSelector((state: RootState) => state.drugs);
  const organizations = useSelector((state: RootState) => state.organizations);
  const bucketOrganization = organizations.find((org) => org.id === bucket.organizationId);
  const dispatch = useDispatch<Dispatch<any>>();
  const isAdmin = checkIsAdmin();
  const isChronoTech = checkIsChronoTech();
  const { t } = useTranslation();

  const choicesIfExpiringSoon = [
    { name: 'Mise à zéro', value: 'toEmpty' },
    { name: 'Décharger seulement ', value: 'toUnload' },
  ];

  const now = getCurrentMonth();
  const oneMonthFromNow = getNextMonthFromNow();

  const isExpired = !!(bucket.nearestExpirationDate && bucket.nearestExpirationDate <= now);
  const isExpiringSoon = !!(
    bucket.nearestExpirationDate &&
    bucket.nearestExpirationDate <= oneMonthFromNow &&
    !isExpired
  );

  const drug = drugs.find((d) => d.DIN === bucket.DIN);

  const handleUnload = async (bucketId: string, emptyLots = false) => {
    handleClose();

    try {
      setBucketUnloading({ ...bucketUnloading, [bucketId]: true });
      await unloadBucket(bucketId, emptyLots)(dispatch);
    } catch (err) {
      if (err instanceof HttpResponseError) {
        const content = await err.response?.json();
        onUnloadingError(content?.error?.message || t('errorOccurred'));
        return;
      }

      console.error('ERROR UNLOADING BUCKET', err);
    } finally {
      setBucketUnloading({ ...bucketUnloading, [bucketId]: false });
    }

    onEnterBucket(bucket);
  };

  const handleClose = () => {
    setModalToEmptyLot(false);
    setModalEditQuantity(false);
  };

  const verifyNearestExpiration = async (bucket: Bucket) => {
    if (isExpiringSoon) {
      setModalToEmptyLot(true);
    } else {
      handleUnload(bucket.id);
    }
  };

  const handleActionChoice = async (bucket: Bucket) => {
    if (chosenAction === 'toEmpty') {
      setModalToEmptyLot(false);
      handleUnload(bucket.id, true);
    } else {
      handleUnload(bucket.id);
    }
  };

  return (
    <tr
      className={classnames({
        deactivated: bucket.isDeactivated,
        isExpired,
        lowQuantity: bucket.quantity < 25,
        lowMonthlyQty: bucket.monthlyQty && bucket.quantity < bucket.monthlyQty * 0.1,
      })}
    >
      <td className="bucket-position">
        {bucket.position}
        {(isAdmin || isChronoTech) &&
          (bucketOrganization ? `-${bucketOrganization.name}` : `-${bucket.organizationId}`)}
      </td>
      <td>
        <div>{bucket.DIN}</div>
      </td>
      <td className="bucket-name-cell">
        <Button variant="link" onClick={() => onEnterBucket(bucket)}>
          {drug ? drug.name.toUpperCase() : t('unknownDrug')}
        </Button>
        {bucket.isDeactivated && !!bucket.deactivatedReason && (
          <span className="deactivated-reason no-strike">
            <FontAwesomeIcon icon="ban" /> : {t(bucket.deactivatedReason)}
          </span>
        )}
      </td>
      <td>
        <div>{drug && drug.force ? drug.force.toUpperCase() : '-'}</div>
      </td>
      <td>
        <div>{drug ? drug.format : '-'}</div>
      </td>
      <td>
        <div>{bucket.monthlyQty}</div>
      </td>
      <td>
        <Button
          variant="link"
          onClick={() => {
            setModalEditQuantity(true);
          }}
        >
          {bucket.quantity}
        </Button>
      </td>

      <td className="actions no-strike">
        <DropdownButton className="no-strike" id="dropdown-basic-button" title="Options">
          <Dropdown.Item onClick={() => verifyNearestExpiration(bucket)}>
            <FontAwesomeIcon icon="arrow-down" /> {t('unload')}
          </Dropdown.Item>
          <Dropdown.Item onClick={() => setEditBucket(true)}>
            <FontAwesomeIcon icon="pen" /> {t('edit')}
          </Dropdown.Item>
          {bucket.isDeactivated && (
            <Dropdown.Item
              disabled={bucket.deactivatedReason === DeactivatedReason.EXPIRED_DRUGS}
              onClick={() => activateBucket(bucket.id)(dispatch)}
            >
              <FontAwesomeIcon icon="check-circle" /> {t('activate')}
            </Dropdown.Item>
          )}
          {!bucket.isDeactivated && (
            <Dropdown.Item onClick={() => setDeactivateBucket(true)}>
              <FontAwesomeIcon icon="ban" /> {t('deactivate')}
            </Dropdown.Item>
          )}
        </DropdownButton>
      </td>

      <EditQuantityModal
        bucket={bucket}
        drug={drug}
        isOpen={modalEditQuantity}
        onClose={handleClose}
      />

      <EditBucketModal show={editBucket} bucket={bucket} close={() => setEditBucket(false)} />

      <Modal show={modalToEmptyLot} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>{t('expireSoon')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t('lotExpiration', { expDate: bucket.nearestExpirationDate })}</p>
          <hr />
          <p>{t('setBucketQtyTo0')}</p>
          <div className="text-center ifExpiringSoon">
            <ButtonGroup>
              {choicesIfExpiringSoon.map((radio, idx) => (
                <ToggleButton
                  key={idx}
                  id={`radio-${idx}`}
                  type="radio"
                  name="radio"
                  variant="outline-primary"
                  value={radio.value}
                  checked={chosenAction === radio.value}
                  onChange={(e) => setChosenAction(e.currentTarget.value)}
                >
                  {radio.name}
                </ToggleButton>
              ))}
            </ButtonGroup>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={() => handleActionChoice(bucket)}>
            Lancer
          </Button>
        </Modal.Footer>
      </Modal>
      <DeactivateBucketModal
        isOpen={deactivateBucket}
        bucket={bucket}
        drug={drug}
        onClose={() => setDeactivateBucket(false)}
      />
    </tr>
  );
};

export default BucketRow;
