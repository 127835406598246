import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'services/store';
import { DrugOrderStatus, Order, OrderStatus } from 'components/orders/types';
import { Bucket } from 'components/buckets/types';
import { LoadingButton } from 'components/shared/LoadingButton';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { t } from 'i18next';
import {
  finishTray,
  patchOrder,
  restoreOrder,
  triggerOrder,
  verifyQuantityInTrays,
} from 'services/orders/endpoints';
import { Dropdown, OverlayTrigger, Popover, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Actions, Warnings } from './ButtonsCell';

interface MainButtonsProps {
  order: Order;
  setAction: (action: Actions) => void;
  setWarning: (warning: Warnings) => void;
  setToRefill: (toRefill: string[]) => void;
  setExpiredDrugs: (expiredDrugs: string[]) => void;
  setButtonTarget: (button: any) => void;
}

const ActionPopover = ({ children, id, title }) => {
  const [show, setShow] = useState(false);

  return (
    <OverlayTrigger
      show={show}
      placement="bottom"
      /* eslint-disable */
      overlay={
        <Popover id={id}>
          {' '}
          <Popover.Content style={{ fontSize: '1rem' }}>{children}</Popover.Content>
        </Popover>
      } /* eslint-enable */
      onToggle={(nextShow) => setShow(nextShow)}
      trigger="click"
      rootClose
    >
      <Button variant="primary" className="text-decoration-none">
        <FontAwesomeIcon icon={faCaretDown} /> {title}
      </Button>
    </OverlayTrigger>
  );
};

const MainButtons = ({
  order,
  setAction,
  setWarning,
  setToRefill,
  setExpiredDrugs,
  setButtonTarget,
}: MainButtonsProps) => {
  const [orderTriggering, setOrderTriggering] = useState<Record<string, boolean>>({});
  const user = useSelector((state: RootState) => state.login);
  const buckets = useSelector((state: RootState) => state.buckets);
  const handleTrigger = async (orderId: string, event) => {
    const hasMachineDistribution: boolean = order.drugs.some((drug) =>
      drug.distributions.some((d) => !d.isExcluded),
    );

    const twoWeeksInMilliseconds = 14 * 24 * 60 * 60 * 1000;
    const twoWeeksPriorToday = new Date(Date.now() - twoWeeksInMilliseconds).toISOString();

    if (!hasMachineDistribution) {
      setButtonTarget(event.target);
      setWarning(Warnings.NO_INCLUDED_DRUGS);
    } else if (order.newestOrder) {
      setWarning(Warnings.NOT_NEW);
    } else if (order.createdAt < twoWeeksPriorToday) {
      setWarning(Warnings.OLDER_TWO_WEEKS);
    } else {
      try {
        setOrderTriggering({ ...orderTriggering, [orderId]: true });
        await triggerOrder(orderId, order.machineId);
        setOrderTriggering({ ...orderTriggering, [orderId]: false });
      } catch (err) {
        setOrderTriggering({ ...orderTriggering, [orderId]: false });
        console.error('ERROR TRIGGERING ORDER', err);
      }
    }
  };

  const handleLinking = async (event) => {
    const hasMachineDistribution: boolean = order.drugs.some((drug) =>
      drug.distributions.some((d) => !d.isExcluded),
    );

    const hasUndistributedExclusions: boolean = order.drugs
      .filter((drug) => !drug.isFlagged)
      .some((drug) =>
        drug.distributions.some(
          (distribution) =>
            distribution.isExcluded && distribution.status === DrugOrderStatus.UNDISTRIBUTED,
        ),
      );

    if (!hasMachineDistribution) {
      setButtonTarget(event.target);
      setWarning(Warnings.NO_INCLUDED_DRUGS);
    } else if (hasUndistributedExclusions) {
      setButtonTarget(event.target);
      setWarning(Warnings.NOT_ALL_DISTRIBUTE);
    } else {
      await verifyRefillAndExpired(
        order,
        buckets,
        setAction,
        setWarning,
        setToRefill,
        setExpiredDrugs,
      );
    }
  };

  const handleRestore = async () => {
    await restoreOrder(order.id);
  };

  switch (order.status) {
    case OrderStatus.PENDING:
      if (user?.permissions?.includes('machine.v-assyst')) {
        return (
          <LoadingButton
            onClick={(event) => handleTrigger(order.id, event)}
            loading={orderTriggering[order.id]}
          >
            {t('send')}
          </LoadingButton>
        );
      }
      return (
        <LoadingButton variant="primary" onClick={handleLinking}>
          {t('link')}
        </LoadingButton>
      );

    case OrderStatus.ONGOING:
    case OrderStatus.FAILED:
      return (
        <LoadingButton
          variant="warning"
          onClick={(event) => handleTrigger(order.id, event)}
          loading={orderTriggering[order.id]}
        >
          {t('sendBack')}
        </LoadingButton>
      );
    case OrderStatus.INTRAY:
      return (
        <LoadingButton variant="primary" onClick={() => setAction(Actions.EDIT_TRAY)}>
          {t('edit')}
        </LoadingButton>
      );
    case OrderStatus.COMPLETED:
    case OrderStatus.CANCELLED:
    case OrderStatus.UNPROCESS:
    case OrderStatus.ARCHIVED:
      return (
        <LoadingButton
          variant="primary"
          onClick={handleRestore}
          loading={orderTriggering[order.id]}
        >
          {t('restore')}
        </LoadingButton>
      );

    case OrderStatus.UNKNOWN:
      return (
        <ActionPopover id="dropdown-basic-button" title={t('manage')}>
          <Dropdown.Item onClick={() => handleRestore()}>
            <FontAwesomeIcon icon="sync" /> {t('restore')}
          </Dropdown.Item>
          <Dropdown.Item onClick={() => patchOrder(order.id, { status: OrderStatus.FAILED })}>
            <FontAwesomeIcon icon="times" /> {t('failed')}
          </Dropdown.Item>

          {order.trayIds.length <= 1 && (
            <Dropdown.Item onClick={() => patchOrder(order.id, { status: OrderStatus.COMPLETED })}>
              <FontAwesomeIcon icon="check" /> {t('completed_One')}
            </Dropdown.Item>
          )}
          {order.trayIds.length > 1 && (
            <>
              {order.trayIds.map((tray) => (
                <Dropdown.Item onClick={() => finishTray(order.id, tray)}>
                  <FontAwesomeIcon icon="check" /> {t('completed_One')}{' '}
                  <FontAwesomeIcon icon="inbox" /> {tray}
                </Dropdown.Item>
              ))}
            </>
          )}
        </ActionPopover>
      );

    default:
      return <></>;
  }
};

export const verifyRefillAndExpired = async (
  order: Order,
  buckets: Bucket[],
  setAction: (action: Actions) => void,
  setWarning: (warning: Warnings) => void,
  setToRefill: (toRefill: string[]) => void,
  setExpiredDrugs: (expiredDrugs: string[]) => void,
) => {
  const insufficientQuantity: string[] = await verifyQuantityInTrays(order.id);
  const thisMonth = new Date().toISOString().slice(0, 7);
  const expiredDrugs: string[] = buckets
    .filter(
      (b) =>
        order.drugs.some(
          (drug) => drug.DIN === b.DIN && drug.distributions.some((d) => !d.isExcluded),
        ) &&
        b.nearestExpirationDate &&
        b.nearestExpirationDate < thisMonth,
    )
    .map((b) => b.id);
  if (insufficientQuantity.length === 0 && expiredDrugs.length === 0) {
    setAction(Actions.LINK);
  } else if (expiredDrugs.length > 0) {
    setWarning(Warnings.EXPIRED_DRUGS_IN_BUCKET);
    setExpiredDrugs(expiredDrugs);
  } else if (insufficientQuantity.length > 0) {
    setWarning(Warnings.REFILL_NEEDED);
    setToRefill(insufficientQuantity);
  }
};
export default MainButtons;
