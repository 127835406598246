import React from 'react';
import { t } from 'i18next';
import demoDashboard from '../images/demoDashboard.png';

const DashboardPage = () => (
  <div>
    <h1
      style={{
        marginLeft: 20,
      }}
    >
      {t('dashboard')}
    </h1>
    <img
      style={{
        marginLeft: 20,
        marginTop: 30,
        height: 750,
      }}
      src={demoDashboard}
      alt="dashboard for the demonstration"
    />
  </div>
);

export default DashboardPage;
