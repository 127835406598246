import { Bucket, DeactivatedReason, EditBucketInfos, Lot } from 'components/buckets/types';
import { Drug } from 'components/drugs/types';
import types from './types';

export type BucketReducerState = Bucket[];

export type BucketAction =
  | { type: types.CREATE_BUCKET_REQUEST; payload: { bucket: Bucket } }
  | { type: types.CREATE_BUCKET_RESPONSE; payload: { bucket: Bucket } }
  | { type: types.GET_BUCKETS_REQUEST }
  | { type: types.GET_BUCKETS_RESPONSE; payload: { buckets: Bucket[] } }
  | {
      type: types.UPDATE_BUCKET_REQUEST;
      payload: { id: string; bucket: Bucket };
    }
  | { type: types.UPDATE_BUCKET_RESPONSE; payload: { bucket: Bucket } }
  | {
      type: types.PATCH_BUCKET_REQUEST;
      payload: { id: string; bucket: Partial<Bucket> };
    }
  | { type: types.PATCH_BUCKET_RESPONSE; payload: { bucket: Bucket } }
  | { type: types.DELETE_BUCKET_REQUEST; payload: { bucketId: string } }
  | { type: types.DELETE_BUCKET_RESPONSE; payload: { bucket: Bucket } }
  | { type: types.ACTIVATE_BUCKET_REQUEST; payload: { bucketId: string } }
  | { type: types.ACTIVATE_BUCKET_RESPONSE; payload: { bucket: Bucket } }
  | {
      type: types.DEACTIVATE_BUCKET_REQUEST;
      payload: { bucketId: string; reason: DeactivatedReason };
    }
  | { type: types.DEACTIVATE_BUCKET_RESPONSE; payload: { bucket: Bucket } }
  | { type: types.UNLOAD_BUCKET_REQUEST; payload: { bucketId: string; emptyLots: boolean } }
  | { type: types.UNLOAD_BUCKET_RESPONSE; payload: { bucket: Bucket } }
  | { type: types.LOAD_BUCKET_REQUEST }
  | { type: types.LOAD_BUCKET_RESPONSE }
  | { type: types.ADD_LOT_REQUEST; payload: { bucketId: string; lots: Lot[] } }
  | { type: types.ADD_LOT_RESPONSE; payload: { bucket: Bucket; lots: Lot[] } }
  | { type: types.EMPTY_LOTS_REQUEST; payload: { bucketId: string } }
  | { type: types.EMPTY_LOTS_RESPONSE; payload: { bucket: Bucket } }
  | {
      type: types.EDIT_QUANTITY_REQUEST;
      payload: { bucketId: string; newQuantity: number; justification: string };
    }
  | { type: types.EDIT_QUANTITY_RESPONSE; payload: { bucket: Bucket } }
  | { type: types.EDIT_BUCKET_REQUEST; payload: { bucketId: string; bucketInfos: EditBucketInfos } }
  | { type: types.EDIT_BUCKET_RESPONSE; payload: { bucket: Bucket } }
  | { type: types.IN_REFILL_LIST_REQUEST; payload: { bucketId: string; isIn: boolean } }
  | { type: types.IN_REFILL_LIST_RESPONSE; payload: { bucket: Bucket } }
  | {
      type: types.IMPORT_BUCKETLIST_REQUEST;
      payload: { bucketList: any; organizationName: string };
    }
  | { type: types.IMPORT_BUCKETLIST_RESPONSE; payload: { buckets: Bucket[]; drugs: Drug[] } };

export default function reducers(
  state: BucketReducerState = null!,
  action: BucketAction,
): BucketReducerState {
  switch (action.type) {
    case types.GET_BUCKETS_RESPONSE: {
      return action.payload.buckets;
    }
    case types.CREATE_BUCKET_RESPONSE:
      return (state || []).concat(action.payload.bucket);
    case types.IMPORT_BUCKETLIST_RESPONSE:
      const newState = state.filter(
        (bucket) => !action.payload.buckets.some((b) => b.id === bucket.id),
      );

      console.log('what the state should be now', newState.concat(action.payload.buckets));

      return newState.concat(action.payload.buckets);
    case types.UPDATE_BUCKET_RESPONSE:
    case types.PATCH_BUCKET_RESPONSE:
    case types.ACTIVATE_BUCKET_RESPONSE:
    case types.DEACTIVATE_BUCKET_RESPONSE:
    case types.EMPTY_LOTS_RESPONSE:
    case types.ADD_LOT_RESPONSE:
    case types.EDIT_QUANTITY_RESPONSE:
    case types.EDIT_BUCKET_RESPONSE:
    case types.UNLOAD_BUCKET_RESPONSE:
    case types.IN_REFILL_LIST_RESPONSE:
      return state.map((bucket) => {
        if (bucket.id === action.payload.bucket.id) {
          return action.payload.bucket;
        }
        return bucket;
      });
    case types.DELETE_BUCKET_RESPONSE:
      return state.filter((bucket) => bucket.id !== action.payload.bucket.id);
    default:
      return state;
  }
}
