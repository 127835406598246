import React, { useState } from 'react';
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory, Link } from 'react-router-dom';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import ResourcesHandler from 'components/ResourcesHandler';
import OrdersTable from 'components/orders/OrdersTable';
import { OrderType } from 'components/orders/types';
import { fetchBuckets } from 'services/buckets/operations';
import { fetchDrugs } from 'services/drugs/operations';
import { fetchSettings } from 'services/settings/operations';
import { RootState } from 'services/store';
import links from 'utils/links';
import { Button } from 'react-bootstrap';
import AddCubiOrderModal from 'components/orders/Cubi/AddCubiOrderModal';
import { PrescriptionSoftware } from 'components/organizations/types';
import { fetchOrganizations } from 'services/organizations/operations';
import { fetchUsers } from 'services/users/operations';

const OrdersPage = (): JSX.Element => {
  const buckets = useSelector((state: RootState) => state.buckets);
  const drugs = useSelector((state: RootState) => state.drugs);
  const settings = useSelector((state: RootState) => state.settings);
  const user = useSelector((state: RootState) => state.login);
  const [addCubiOrder, setAddCubiOrder] = useState<boolean>(false);
  const dispatch = useDispatch<Dispatch<any>>();
  const { search } = useLocation();
  const { status }: { status?: OrderType } = queryString.parse(search);
  const { t } = useTranslation();
  const history = useHistory();
  const organizations = useSelector((state: RootState) => state.organizations);
  const prescriptionSoftware = organizations.find(
    (org) => org.id === user.organizationId,
  )?.prescriptionSoftware;
  const getPage = () => (
    <>
      <AddCubiOrderModal show={addCubiOrder} close={() => setAddCubiOrder(false)} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <h2>{t('orders')}</h2>
        {prescriptionSoftware && prescriptionSoftware.name === PrescriptionSoftware.Cubi && (
          <Button onClick={() => setAddCubiOrder(true)}>+ CUBI</Button>
        )}
      </div>
      <Tabs
        defaultActiveKey={status || OrderType.ACTIVE}
        onSelect={(e) => {
          history.push(links.orders(e! as OrderType));
        }}
      >
        <Tab
          eventKey={OrderType.ACTIVE}
          title={<Link to={links.orders(OrderType.ACTIVE)}>{t('active')}</Link>}
        />
        <Tab
          eventKey={OrderType.COMPLETED}
          title={<Link to={links.orders(OrderType.COMPLETED)}>{t('completed_Other')}</Link>}
        />
        {settings && !settings.hideArchivedOrders && (
          <Tab
            eventKey={OrderType.ARCHIVED}
            title={<Link to={links.orders(OrderType.ARCHIVED)}>{t('archived')}</Link>}
          />
        )}
      </Tabs>
      <OrdersTable status={status} />
    </>
  );

  return (
    <ResourcesHandler
      resources={[drugs, buckets, organizations, settings]}
      resourceFetchers={[
        () => dispatch(fetchDrugs()),
        () => dispatch(fetchBuckets()),
        () => dispatch(fetchOrganizations()),
        () => dispatch(fetchSettings()),
        () => dispatch(fetchUsers()),
      ]}
      getContents={getPage}
    />
  );
};

export default OrdersPage;
