import React, { SyntheticEvent, useState } from 'react';
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from 'components/shared/LoadingButton';
import { updateOrdersInclusions, updateUnprocess } from 'services/orders/endpoints';
import { patchSettings } from 'services/settings/operations';
import { RootState } from 'services/store';
import { getLanguage, setLanguage } from 'utils/translation';
import { Form, Tabs, Tab, Button } from 'react-bootstrap';
import { Drug } from 'components/drugs/types';
import { checkIsAdmin } from 'utils/permissions';
import UPCValidationList from './UPCValidationList';
import './style.scss';

function SettingsTable(): JSX.Element {
  const { hideArchivedOrders } = useSelector((state: RootState) => state.settings);
  const { archiveDelay } = useSelector((state: RootState) => state.settings);
  const dispatch = useDispatch<Dispatch<any>>();
  const { t } = useTranslation();
  const language: string = getLanguage();
  const [isLoadingInclusions, setIsLoadingInclusions] = useState(false);
  const [isLoadingUnprocess, setIsLoadingUnprocess] = useState(false);
  const [validateNewUPC, setValidateNewUPC] = useState<boolean>(false);
  const [newArchiveDelay, setNewArchiveDelay] = useState<number>(archiveDelay);
  const [isSaving, setIsSaving] = useState(false);
  const drugs = useSelector((state: RootState) => state.drugs);
  const UPCsToValidate: Drug[] = drugs.filter((d) => d.newUPC.length > 0);
  const isAdmin = checkIsAdmin();

  const archiveDelayOptions = [
    { value: '14', label: t('archiveDelayTwoWeeks') },
    { value: '30', label: t('archiveDelayOneMonth') },
    { value: '90', label: t('archiveDelayThreeMonths') },
    { value: '180', label: t('archiveDelaySixMonths') },
    { value: '365', label: t('archiveDelayOneYear') },
  ];

  const handleSelectLanguage = (e: SyntheticEvent) => {
    const newLanguage = (e.target as HTMLInputElement).value;
    setLanguage(newLanguage);
  };

  const handleSaveDelay = async () => {
    setIsSaving(true);
    try {
      await patchSettings({ archiveDelay: newArchiveDelay })(dispatch);
    } catch (error) {
      console.error('Failed to update settings:', error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleChangehideArchivedOrders = (e: SyntheticEvent) => {
    const newHideArchivedOrders = (e.target as HTMLInputElement).checked;
    patchSettings({ hideArchivedOrders: newHideArchivedOrders })(dispatch);
  };

  const handleUpdateOrdersInclusions = async () => {
    setIsLoadingInclusions(true);
    await updateOrdersInclusions();
    setIsLoadingInclusions(false);
  };

  const handleUnprocessedOrders = async () => {
    setIsLoadingUnprocess(true);
    await updateUnprocess();
    setIsLoadingUnprocess(false);
  };

  return (
    <section>
      <div>
        <Tabs defaultActiveKey="general">
          <Tab eventKey="general" title={t('general')}>
            <br />
            <h4>{t('language')}</h4>
            <Form>
              <div className="mb-3">
                <Form.Check
                  type="radio"
                  name="language"
                  label="Français"
                  value="fr"
                  defaultChecked={language === 'fr'}
                  onChange={handleSelectLanguage}
                />
                <Form.Check
                  type="radio"
                  name="language"
                  label="English"
                  value="en"
                  defaultChecked={language === 'en'}
                  onChange={handleSelectLanguage}
                />
              </div>
            </Form>
          </Tab>
          <Tab eventKey="actions" title={t('actions')}>
            <br />
            <div className="actions-tab">
              <div className="actions-buttons">
                <div>
                  <LoadingButton
                    loading={isLoadingInclusions}
                    onClick={() => {
                      handleUpdateOrdersInclusions();
                    }}
                  >
                    {t('updateOrders')}
                  </LoadingButton>
                </div>
                <div>
                  <LoadingButton
                    loading={isLoadingUnprocess}
                    onClick={() => handleUnprocessedOrders()}
                  >
                    {t('updateUnprocess')}
                  </LoadingButton>
                </div>
                {isAdmin && (
                  <div className="upc-validation">
                    <Button onClick={() => setValidateNewUPC(true)}>
                      {t('validateNewUpc')} ({UPCsToValidate.length})
                    </Button>
                  </div>
                )}
              </div>
              <div className="actions-ui">
                {validateNewUPC && (
                  <div>
                    <h2>Validation des UPCs</h2>
                    <UPCValidationList drugs={UPCsToValidate} />
                  </div>
                )}
              </div>
            </div>
          </Tab>
          <Tab eventKey="orders" title={t('orders')}>
            <br />
            <Form>
              <Form.Check
                id="hideArchivedOrdersSwitch"
                label={t('hideArchivedOrders')}
                onChange={handleChangehideArchivedOrders}
                defaultChecked={hideArchivedOrders}
                type="switch"
              />
            </Form>
            <br />
            <h5>{`${t('archiveDelay')} :`}</h5>
            <div className="delayArchive">
              <Form.Control
                as="select"
                onChange={(e) => setNewArchiveDelay(parseInt(e.target.value, 10))}
                style={{ width: '8%' }}
                value={newArchiveDelay} // Use 'value' instead of 'defaultValue'
              >
                {archiveDelayOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Control>
              <LoadingButton
                disabled={newArchiveDelay === archiveDelay}
                onClick={handleSaveDelay}
                loading={isSaving}
              >
                {t('save')}
              </LoadingButton>
            </div>
          </Tab>
        </Tabs>
      </div>
    </section>
  );
}

export default SettingsTable;
