import React from 'react';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { t } from 'i18next';
import { Button } from 'react-bootstrap';
import { activateBucket, inRefillList } from 'services/buckets/operations';
import { HttpResponseError } from 'services/api';
import { Bucket } from '../types';

interface BucketBORowProps {
  bucket: Bucket;
  setRefillBucketId: (bucketId: string) => void;
  startUnloading: (bucketId: string) => void;
  drugs: any;
}

const BucketBORow = ({ bucket, setRefillBucketId, startUnloading, drugs }: BucketBORowProps) => {
  const dispatch = useDispatch<Dispatch<any>>();

  const activateAndRefill = async () => {
    try {
      await activateBucket(bucket.id)(dispatch);
      await inRefillList(bucket.id, true)(dispatch);
      setRefillBucketId(bucket.id);
      startUnloading(bucket.id);
    } catch (err) {
      if (err instanceof HttpResponseError) {
        const content = await err.response?.json();
        console.error('ERROR HANDLING BO/ORDERED BUCKET', content);
      }
    }
  };

  return (
    <div>
      <tr>
        <td id="refillDone" />
        <td id="bucketPosition">#{bucket.position}</td>
        <td id="bucketDrug">
          {drugs.find((d) => d.DIN === bucket.DIN)?.name}{' '}
          {drugs.find((d) => d.DIN === bucket.DIN)?.force}{' '}
        </td>
        <td id="bucketDIN"> DIN {bucket.DIN}</td>
        <td id="refillBtns">
          <div>
            <Button onClick={activateAndRefill}>{t('unload')}</Button>
          </div>
        </td>
      </tr>
      <hr />
    </div>
  );
};

export default BucketBORow;
