import React from 'react';
import { t } from 'i18next';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'services/store';
import { inRefillList } from 'services/buckets/operations';
import { Order } from '../types';

interface RefillNeededModalProps {
  show: boolean;
  bucketsId: string[];
  order: Order | null;
  refill: () => void;
  link: (order: Order) => void;
  handleClose: () => void;
}

function RefillNeededModal({
  show,
  bucketsId,
  order,
  refill,
  link,
  handleClose,
}: RefillNeededModalProps) {
  const dispatch = useDispatch<Dispatch<any>>();
  const drugs = useSelector((state: RootState) => state.drugs);
  const allBuckets = useSelector((state: RootState) => state.buckets);
  const buckets = allBuckets.filter((b) => bucketsId.includes(b.id));

  const handleRefill = () => {
    refill();
    handleClose();
  };

  const addToRefillLater = async () => {
    await Promise.all(bucketsId.map((bucketId) => inRefillList(bucketId, true)(dispatch)));
    link(order!);
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" centered className="error-modal">
      <Modal.Header closeButton>
        <Modal.Title>{t('fillingRequired')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {t('notEnoughtQtyForTrays', {
          lookupId: order?.lookupId,
          patient: order?.patient,
        })}
        <br />{' '}
        {buckets.map((bucket) => (
          <div>
            {' '}
            #{bucket.position} {drugs.find((d) => d.DIN === bucket.DIN)?.name}{' '}
            {drugs.find((d) => d.DIN === bucket.DIN)?.force} (DIN {bucket.DIN}){'  '}
            {t('monthlyQty')}
            {' :'} {bucket.monthlyQty}
          </div>
        ))}
        <br />
        {t('whatDoYouWantToDo')}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleRefill}>{t('refillNow')}</Button>
        <Button onClick={addToRefillLater}>{t('addToScheduled')}</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default RefillNeededModal;
